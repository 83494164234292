<template lang="html">
  <div>
    <v-data-table
      id="datatable"
      v-model="selected"
      show-select
      class="elevation-1"
      item-key="id_contrato"
      multi-sort
      :headers="headers"
      :items="contratos"
      @toggle-select-all="selectAllToggle"
      :options.sync="pagination"
      :server-items-length="totalPage"
      :no-data-text="$t('label.tabela_sem_conteudo')"
      :footer-props="{
        itemsPerPageOptions: [10, 25, 50],
      }">
      <template v-slot:item.novo_prazo_vencimento="{ item }">
        <v-text-field
          v-model="item.novo_prazo_vencimento"
          id="novoPrazoVencimento"
          min="0"
          type="number"></v-text-field>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  buscaAutocomplete,
  triggerSelecao,
} from '@/produto/common/functions/autocomplete-utils';
import { buscarContratoPorGrupoFornecedorData } from '../../../common/resources/planejamento/planejamento-contrato-listagem';
import PopoverLista from '../../../shared-components/PopoverLista';

export default {
  name: 'AlterarPrazoVencimentoTabela',
  components: { PopoverLista },
  props: {
    contrato: {
      type: Object,
    },
    filtros: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      workflowAcaoMagaluResource: this.$api.workflowAcaoMagalu(this.$resource),

      selected: [],
      pagination: {},
      totalPage: 0,
      esperar: false,
      contratos: [],
      todosContratos: [],
      recebimentoSemValorNaoEmitido: true,
      listaTiposUso: [],
      dialogJustificativa: false,
      contratoSelecionado: {},
      rules: {
        required: (value) => !!value || this.$t('message.campo_obrigatorio'),
        positivo: (value) => !!Number.isInteger(Number(value)) || this.$t('message.valor_positivo'),
      },
      headers: [
        { text: this.$tc('label.codigo', 1), value: 'id_contrato' },
        { text: this.$tc('label.descricao', 1), value: 'descricao' },
        { text: this.$tc('label.fornecedor', 1), value: 'origem' },
        { text: this.$tc('label.data_inicio', 1), value: 'data_inicio' },
        { text: this.$tc('label.data_fim', 1), value: 'data_fim' },
        { text: this.$tc('label.prazo_vencimento_atual', 1), value: 'prazo_vencimento' },
        { text: this.$tc('label.novo_prazo_vencimento', 1), value: 'novo_prazo_vencimento', sortable: false },
      ],
    };
  },
  watch: {
    pagination: {
      handler() {
        if (this.filtros && this.filtros.ano) {
          this.filtrar();
        }
      },
      deep: true,
    },
    selected() {
      this.$emit('AlterarPrazoVencimentoTabela_atualizarQtdContratosSelecionados', this.selected.length);
    },
  },
  methods: {
    buscaAutocomplete,
    triggerSelecao,
    filtrar() {
      if (this.esperar) return;

      this.esperar = true;
      setTimeout(() => {
        this.buscarContratos();
      }, 1000);
    },
    pararEsperar() {
      setTimeout(() => {
        this.esperar = false;
      }, 2E2);
    },
    selectAllToggle() {
      const buscaCompleta = true;
      const zeraPaginacao = false;
      this.buscarContratos(zeraPaginacao, buscaCompleta)
        .then(() => {
          if (this.selected.length !== this.todosContratos.length
            && this.selected.length !== (this.todosContratos.length - this.contratos.length)) {
            this.selected = [...this.todosContratos];
          } else {
            this.selected = [];
            this.todosContratos = [];
          }
          this.$forceUpdate();
        });
    },
    buscarContratos(zeraPaginacao = null, buscaCompleta = false) {
      if (zeraPaginacao) {
        this.pagination.page = 1;
      }
      if (!this.filtros.ano) {
        this.filtros.ano = new Date().getFullYear();
      }
      const params = {
        ...this.filtros,
        asc: !this.pagination.sortDesc[0],
        colunaOrdenacao: this.pagination.sortBy[0],
      };

      if (buscaCompleta) {
        params.numeroPagina = 1;
        params.tamanhoPagina = 99999;
      } else {
        params.numeroPagina = this.pagination.page;
        params.tamanhoPagina = this.pagination.itemsPerPage;
      }

      return buscarContratoPorGrupoFornecedorData(params, this.$resource)
        .then((response) => {
          if (buscaCompleta) {
            this.todosContratos = response.data.resposta;
          } else {
            this.contratos = response.data.resposta;
            if (this.todosContratos.length) {
              this.contratos.forEach((contrato) => {
                const c = this.todosContratos.find((tc) => tc.id_contrato === contrato.id_contrato);
                contrato.novo_prazo_vencimento = c.novo_prazo_vencimento;
              });
            }
            this.totalPage = response.data.quantidadeRegistrosPagina;
          }
          this.$forceUpdate();
          this.pararEsperar();
        })
        .catch((err) => {
          this.$error(this, err);
          this.pararEsperar();
        });
    },
    aplicarNovoPrazoVencimentoLote(novoPrazo) {
      if (this.todosContratos.length) {
        this.todosContratos
          .filter((contrato) => this.selected.some((c) => c.id_contrato === contrato.id_contrato))
          .forEach((contrato) => {
            contrato.novo_prazo_vencimento = novoPrazo;
          });
      }
      this.contratos
        .filter((contrato) => this.selected.some((c) => c.id_contrato === contrato.id_contrato))
        .forEach((contrato) => {
          contrato.novo_prazo_vencimento = novoPrazo;
        });
      this.$forceUpdate();
    },
    buscaContratosSelecionados() {
      let listaContratos = this.contratos;
      if (this.todosContratos.length) {
        listaContratos = this.todosContratos;
      }
      return listaContratos.filter((contrato) => this.selected.some((c) => c.id_contrato === contrato.id_contrato));
    },
    buscaContratosAlterados() {
      return this.contratos
        .filter((contrato) => contrato.novo_prazo_vencimento != null && contrato.novo_prazo_vencimento.length);
    },
    limpaSelecionados() {
      this.selected = [];
      this.todosContratos = [];
    },
  },
  mounted() {
  },
};
</script>
